import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AppDisplayService } from '../../core/services/app-display.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppRoutingLogin, AppRoutingNewPassword } from '../../app-routing.model';
import { InputError } from '../../shared/models/input-error';
import { of, switchMap } from 'rxjs';
import { PasswordService } from '../../core/services/password.service';
import { delay, first, map } from 'rxjs/operators';
import { movinMotionApp } from '../../core/services/app-display.model';
import { OtpInputComponent } from '../../shared/components/otp-input/otp-input.component';
import { MixpanelService } from '@movinmotion/data-access-third-party';

const RESEND_CODE_DELAY_IN_MINUTES = 5;

@UntilDestroy()
@Component({
  selector: 'mm-page-change-password',
  templateUrl: './page-change-password.component.html',
  styleUrls: ['./page-change-password.component.scss'],
})
export class PageChangePasswordComponent implements OnInit {
  appDisplayService = inject(AppDisplayService);

  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private translate = inject(TranslateService);
  private location = inject(Location);
  private passwordService = inject(PasswordService);
  private mixpanel = inject(MixpanelService);

  @ViewChild('otpInput') otpInput!: OtpInputComponent;

  /**
   * Form fields names
   */
  formFieldKeys = {
    code: 'code',
  };

  /**
   * Errors control for the code input
   */
  codeErrors: InputError[] = [
    {
      isError: control => control?.hasError('incorrectCode'),
      text$: this.translate.stream(_('mm.pages.change-password.errors.incorrectCode')),
    },
  ];

  /**
   * The change password form
   */
  form = new FormGroup({
    [this.formFieldKeys.code]: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
  });

  /**
   * Handle loading state during submit login
   */
  submitLoading = false;

  /**
   * User's email
   */
  email: string;

  /**
   * Resend code delay in minutes
   */
  resendCodeDelayInMinutes = RESEND_CODE_DELAY_IN_MINUTES;

  /**
   * Flag allowing to resend code
   */
  canResendCode = false;

  /**
   * Number of resent codes
   */
  resentCodesCounter = 0;

  ngOnInit(): void {
    this.appDisplayService
      .setTitle$(this.translate.stream(_('mm.pages.change-password.title.head')))
      .pipe(untilDestroyed(this))
      .subscribe();

    this.startTimerBeforeAllowingResendCode();

    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe(params => {
      if (params.has(environment.queryParamsKeys.email) && params.get(environment.queryParamsKeys.email)) {
        this.email = params.get(environment.queryParamsKeys.email);
      } else {
        this.goToLoginPage();
      }
    });
    this.form
      .get(this.formFieldKeys.code)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        delete this.form.get(this.formFieldKeys.code).errors?.incorrectCode;
      });
  }

  /**
   * Wait for the appropriate delay before enabling the resend code button
   */
  startTimerBeforeAllowingResendCode(): void {
    this.canResendCode = false;
    of(true)
      .pipe(untilDestroyed(this), delay(this.resendCodeDelayInMinutes * 60 * 1000))
      .subscribe(() => {
        this.canResendCode = true;
      });
  }

  /**
   * Resend a code, empty the code input, focus it, and increment the number of resent codes counter
   */
  resendCode(): void {
    if (this.canResendCode) {
      this.canResendCode = false;
      this.form.get(this.formFieldKeys.code).reset();
      this.appDisplayService
        .getDisplay$()
        .pipe(
          first(),
          map(application => movinMotionApp[application]),
          switchMap(application => this.passwordService.otpRequest$({ email: this.email, application })),
        )
        .subscribe(() => {
          this.resentCodesCounter++;
          this.startTimerBeforeAllowingResendCode();
          this.otpInput.focus();
        });
    }
  }

  /**
   * The change password submit of the form
   */
  submit(): void {
    this.submitLoading = true;
    const otp = this.form.get(this.formFieldKeys.code).value;
    const email = this.email;
    this.mixpanel.track({ eventName: 'Confirmer le code de vérification', sendImmediately: true });
    this.appDisplayService
      .getDisplay$()
      .pipe(
        first(),
        map(application => movinMotionApp[application]),
        switchMap(application => this.passwordService.otpValidation$({ email, application, otp })),
      )
      .subscribe({
        next: otp2 => {
          this.router.navigate([AppRoutingNewPassword], {
            queryParams: { otp: otp2.secret },
            queryParamsHandling: 'merge',
          });
        },
        error: error => {
          this.submitLoading = false;
          if (error.status === 403) {
            this.form.get(this.formFieldKeys.code).setErrors({ incorrectCode: true });
          }
          console.error(error);
        },
      });
  }

  /**
   * Go back to previous page
   */
  goBack(): void {
    this.location.back();
  }

  /**
   * Go to login page, passing appropriate query params such as redirection URL
   */
  goToLoginPage(): void {
    const queryParams: Params = {};
    if (
      this.route.snapshot.queryParamMap.has(environment.queryParamsKeys.redirectUrl) &&
      this.route.snapshot.queryParamMap.get(environment.queryParamsKeys.redirectUrl)
    ) {
      queryParams[environment.queryParamsKeys.redirectUrl] = this.route.snapshot.queryParamMap.get(
        environment.queryParamsKeys.redirectUrl,
      );
    }
    this.router.navigate([AppRoutingLogin], { queryParams }).then();
  }
}
